import { useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import * as Sentry from "@sentry/react";
import { MoreHorizontal } from "react-feather";
import { colorCode } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { getCurrenySymbol } from "../../../common/utils/currenySymbol";
import PaginationCommon from "../../../common/components/pagination-common";
import { getPageName } from "../../../../../marketing-cloud-container/src/ga-events";
import { approveCampaign } from "../../../common/services/message-services";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { MESSAGE } from "../../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import {
  EditCampaign,
  EmailInsight,
  Pause,
  RcsInsight,
  Resume,
  SmsInsight,
  WhatsappInsight,
} from "../../../assets/icons/icons";

const MessagesList = ({
  messageList = [],
  totalListLength,
  country,
  currency,
  navigate,
  setMessageText,
  messageText,
  setSize,
  size,
  setPageNumber,
  pageNumber,
  setFrom,
  filter,
  setFilter,
  hasSuperAdminAccess = false,
  tenantContext,
}) => {
  const [messages, setMessages] = useState(messageList);
  const currencySymbol = getCurrenySymbol(country, currency);
  const [menuKey, setMenuKey] = useState(null);
  const [showEventMenu, setShowEventMenu] = useState(false);
  const eventMenu = useRef(null);

  useEffect(() => {
    setMessages(messageList);
  }, [messageList]);

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (
        eventMenu.current &&
        showEventMenu &&
        !eventMenu.current.contains(e.target)
      ) {
        setShowEventMenu(false);
      }
    };

    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [showEventMenu]);

  const navigateToDetail = (message) => {
    const { channel = "" } = message?._source;
    if (channel?.toLowerCase() === "email") {
      navigate(`/engagements/email-report/${message._id}`);
    } else {
      navigate(`/engagements/${message._id}`);
    }
  };

  const dateTimeFormatter = (timestamp) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  };

  const capitalizeFirstLetter = (str: string) => {
    str = str.trim().toLowerCase();
    const status = str.charAt(0).toUpperCase() + str.slice(1);
    return status;
  };

  const insightIcon = (channel) => {
    switch (channel?.toLowerCase()) {
      case "sms":
        return <img src={SmsInsight} alt="" />;
      case "email":
        return <img src={EmailInsight} alt="" />;
      case "whatsapp":
        return <img src={WhatsappInsight} alt="" />;
      case "rcs":
        return <img src={RcsInsight} alt="" />;
    }
  };

  const modifyCampaignStatus = (
    approvalStatus: String,
    campaignType: String,
    campaignId: String,
    index: number
  ) => {
    const payload = {
      approvalStatus,
      campaignType,
      campaignId,
    };
    try {
      setShowEventMenu(!showEventMenu);

      approveCampaign({ payload })
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            const updatedMessageList = messages.map((message, idx) => {
              if (idx === index) {
                return {
                  ...message,
                  _source: {
                    ...message._source,
                    status: approvalStatus,
                  },
                };
              } else {
                return message;
              }
            });
            setMessages(updatedMessageList);

            Notify({
              type: "success",
              heading: `Campaign ${approvalStatus}`,
              message: response,
            });
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const handleClick = (campaignStatus: string, messageItem) => {
    if (
      [
        "active",
        "running",
        "pause",
        "paused",
        "resume",
        "resumed",
        "completed",
      ].includes(campaignStatus?.toLowerCase())
    ) {
      navigateToDetail(messageItem);
    } else if (campaignStatus.toLowerCase() === "pending approval") {
      navigate(`/engagement-review/${messageItem._id}`);
    }
  };

  return (
    <div>
      <div className="pt-7 flex justify-between">
        <div className="flex border border-gray-300 rounded-lg ">
          <div className="w-10 h-10 text-gray-400">
            <Search className="text-xl m-3" size="16" />
          </div>
          <input
            type="text"
            className="w-full h-10 pt-0.5 pr-28 mr-2 focus:border-none font-normal focus:outline-none triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_search-filter_click`}
            data-event-action="search-input_click"
            value={messageText}
            onChange={(e: any) => {
              setMessageText(e.target.value);
              setFilter(true);
              // TODO: To setFilter(false) when input box is empty
            }}
            placeholder="Search messages"
          />
        </div>
      </div>

      <div className="flex align-start flex-col gap-6 ">
        <div className="mt-11 relative">
          <table className="min-w-full">
            <thead>
              <tr>
                {["Name", "Spend", "Status", "Date Created", ""].map(
                  (item, index) => (
                    <th
                      key={index}
                      className={
                        "py-3 border-b border-gray-100 text-left text-gray-700"
                      }
                    >
                      <div
                        className={` ${index === 0 ? "w-44" : ""} ${
                          index === 1 ? "w-28" : ""
                        }`}
                      >
                        {item}
                      </div>
                    </th>
                  )
                )}
              </tr>
            </thead>

            {messageList?.length === 0 ? (
              <div className="w-full p-24 absolute">
                <div className="flex w-full flex-col bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                  <div className="mt-2">No data to show</div>
                </div>
              </div>
            ) : (
              <tbody>
                {messages?.map((messageItem, index) => {
                  const item = messageItem._source;
                  const showMenu = menuKey === index;

                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-100 text-gray-70 cursor-pointer"
                    >
                      <td
                        className="py-6 text-base leading-6"
                        onClick={() => handleClick(item.status, messageItem)}
                      >
                        <div className="flex ">
                          <div>{insightIcon(item.channel)}</div>

                          <div className="flex justify-center items-center pl-2">
                            {item.name}
                          </div>
                        </div>
                      </td>

                      <td
                        className="text-base leading-6"
                        onClick={() => handleClick(item.status, messageItem)}
                      >
                        {currencySymbol}
                        {Number(item.spend ? item.spend : 0).toLocaleString()}
                      </td>
                      <td onClick={() => handleClick(item.status, messageItem)}>
                        <button
                          className={`${colorCode(
                            item.status
                          )} font-medium text-[12px]  leading-5 py-0.5 px-2 rounded-2xl`}
                        >
                          <div>{capitalizeFirstLetter(item.status)}</div>
                        </button>
                      </td>
                      <td
                        className="text-base leading-6"
                        onClick={() => handleClick(item.status, messageItem)}
                      >
                        {dateTimeFormatter(item.createdAt)}
                      </td>

                      {((!hasSuperAdminAccess &&
                        ["draft", "rejected"].includes(
                          item.status.toLowerCase()
                        )) ||
                        hasSuperAdminAccess) && (
                        <td className="py-6 relative">
                          <button
                            className="flex justify-end items-center pr-6"
                            onClick={() => {
                              setMenuKey(index);
                              setShowEventMenu(!showEventMenu);
                            }}
                          >
                            <MoreHorizontal size={20} />
                          </button>
                          {showMenu && showEventMenu && (
                            <div
                              ref={eventMenu}
                              className="absolute mt-2 -ml-2 z-10 bg-white rounded-lg shadow min-w-fit"
                            >
                              <ul className="p-0.5 text-gray-700">
                                {["draft", "rejected"].includes(
                                  item.status?.toLowerCase()
                                ) && (
                                  <li>
                                    <div className="group">
                                      <button
                                        className={`flex gap-2.5 items-center p-3 rounded ${
                                          tenantContext.productList[
                                            "edit_message_policy"
                                          ] && "hover:bg-gray-100"
                                        } relative`}
                                        onClick={() => {
                                          navigate(
                                            `/engagements/${item.channel.toLowerCase()}/${
                                              item.id
                                            }`
                                          );
                                        }}
                                        disabled={
                                          !tenantContext.productList[
                                            "edit_message_policy"
                                          ]
                                        }
                                      >
                                        <img
                                          className="-mt-1"
                                          src={EditCampaign}
                                          alt=""
                                        />
                                        <div className="w-full font-medium rounded cursor-pointer">
                                          Edit
                                        </div>
                                        {!tenantContext.productList[
                                          "edit_message_policy"
                                        ] && (
                                          <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[76px] top-[-12px] z-20">
                                            {MESSAGE}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                  </li>
                                )}

                                {hasSuperAdminAccess &&
                                  [
                                    "active",
                                    "running",
                                    "resume",
                                    "resumed",
                                  ].includes(item.status?.toLowerCase()) && (
                                    <li>
                                      <div
                                        className="flex gap-2.5 items-center p-3 rounded hover:bg-gray-100"
                                        onClick={() =>
                                          modifyCampaignStatus(
                                            "Paused",
                                            item.campaignType,
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        <div>
                                          <img src={Pause} alt="" />
                                        </div>
                                        <div className="w-full font-medium rounded cursor-pointer">
                                          Pause
                                        </div>
                                      </div>
                                    </li>
                                  )}

                                {hasSuperAdminAccess &&
                                  ["pause", "paused"].includes(
                                    item.status?.toLowerCase()
                                  ) && (
                                    <li>
                                      <div
                                        className="flex gap-2.5 items-center p-3 rounded hover:bg-gray-100"
                                        onClick={() =>
                                          modifyCampaignStatus(
                                            "Resumed",
                                            item.campaignType,
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        <div>
                                          <img src={Resume} alt="" />
                                        </div>
                                        <div className="w-full font-medium rounded cursor-pointer">
                                          Resume
                                        </div>
                                      </div>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>

        {pageNumber === 0 && messageList?.length === 0 ? null : (
          <div className="pt-6 flex gap-5 text-sm items-center ">
            <PaginationCommon
              ListLength={messageList.length}
              totalListLength={totalListLength}
              filter={filter}
              setPageSize={setSize}
              setPageNum={setPageNumber}
              pageNum={pageNumber}
              setFrom={setFrom}
              pageSize={size}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesList;
